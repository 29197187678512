<template>
  <b-modal v-model="show" hide-footer hide-header centered no-fade id="modal-backdrop-swiper" size="lg">
    <div class="swiper-container">
      <div class="swiper-wrapper" ref="swiperWrapper">
        <div class="swiper-slide" v-for="(data, index) in swiper_info" :key="index">
          <b-img 
            :src="data.img" 
            fluid 
            v-if="data.img" 
            class="image-swiper"
            @error="errorLoad(data.img)" 
          />
          <video :src="data.video" v-else class="video-swiper" controls muted :ref="`video-${index}`"></video>
        </div>
      </div>
      <div @click="show = false" class="container-x-icon-close-swiper">
        <feather-icon class="swiper-button-prev" icon="XIcon"></feather-icon>
      </div>
      <div @click="prevSlide" v-if="current_slide !== 0" class="container-controls-swiper left-position">
        <feather-icon class="swiper-button-prev" icon="ChevronLeftIcon"></feather-icon>
      </div>
      <div @click="nextSlide" class="container-controls-swiper right-positon">
        <feather-icon class="swiper-button-next" icon="ChevronRightIcon"></feather-icon>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BImg,
} from 'bootstrap-vue';

export default {
  name: 'swiperBrandme',
  props: {
    open_modal_images: {
      type: Boolean,
      default: false
    },
    swiper_data:{
      type: Array,
      required: true,
    },
    current_slide_index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      show: this.open_modal_images,
      current_slide: this.current_slide_index,
      swiper_info: this.swiper_data,
    }
  },
  components: {
    BModal,
    BImg,
  },
  created() {
    setTimeout(() => {
      this.updateSwiper();
    }, 5);
    setTimeout(() => {
      this.$refs.swiperWrapper.classList.add('transform-class')
    }, 10);
  },
  methods: {
    errorLoad(image) {
      const new_array = []
      this.swiper_info.map((data) => {
        if (data.img === image) data.img = require("@/assets/images/dashboard/imgDefault.png");
        new_array.push(data);
      });
      this.swiper_info = new_array;
    },
    pauseVideos() {
      this.swiper_info.forEach((asset, index) => {
        if (asset.video) this.$refs[`video-${index}`][0].pause();
      })
    },
    prevSlide() {
      this.current_slide = Math.max(this.current_slide - 1, 0);
      this.updateSwiper();
    },
    nextSlide() {
      this.current_slide = (this.current_slide + 1) % this.$refs.swiperWrapper.children.length;
      this.updateSwiper();
    },
    updateSwiper() {
      this.$refs.swiperWrapper.style.transform = `translateX(-${this.current_slide * 100}%)`;
      this.pauseVideos();
    },
  }
}
</script>

<style>
#modal-backdrop-swiper {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  opacity: 9;
}
#modal-backdrop-swiper .modal-content {
  border-radius: 2em;
  background-color: transparent;
  box-shadow: none;
}
#modal-images .modal-body {
  padding: 0 !important;
  max-height: 700px !important;
}
</style>
<style scoped lang="scss">
.container-x-icon-close-swiper {
  position: absolute;
  top: 3%;
  right: 3%;
  background-color: white;
  border-radius: 100%;
  cursor: pointer;
}
.swiper-container {
  width: 100%;
  height: 700px;
  overflow: hidden;
  position: relative;
  border-radius: 2em;
  animation: zoom-in 1s ease-in-out;
}

.swiper-wrapper {
  display: flex;
  height: 100%;
}
.transform-class {
  transition: transform 0.3s ease-in-out;
}
@keyframes zoom-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}
.container-controls-swiper {
  position: absolute;
  top: 46%;
}
.left-position {
  left: 1em;
}
.right-positon {
  right: 1em;
}
/* Estilos opcionales para los botones de navegación y la paginación */
.swiper-button-prev,
.swiper-button-next {
  color: black;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  height: auto;
  padding: 9px;
}
.video-swiper {
  width: 100%;
  height: 700px;
  background-color: black;
  object-fit: contain;
}
.image-swiper {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2em;
}
</style>